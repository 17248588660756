import {Routes, Route} from 'react-router-dom';
// Public
import Home from './pages/Home';
import Footer from './layouts/Footer';
import { UilWhatsapp } from '@iconscout/react-unicons';

const Main = () => {
  const company =  'Zyzics IT Training and Software Ltd.';

  return (
    <>
    <Routes>
      {/* Public */}
      <Route path="" element={<Home company={company} />} />
    </Routes>

    <Footer company={company} />

    {/* <a href="https://wa.me/message/2QC2GFREBENHD1" title="WhatsApp Chat">
      <UilWhatsapp className="chatLogo" size="50" />
    </a> */}
    </>
  );
}
export default Main;





// import Conference_Registration from './pages/conference/Conference_Registration';
// import Registration from './pages/member/Registration';
// import Login from './pages/member/Login';
// import Dashboard from './pages/member/Dashboard';
// import Logout from './pages/member/Logout';
// import ContentManagement from './pages/cms/ContentManagement';
// // Add
// import AddActivity from './pages/activity/AddActivity';
// import AddZone from './pages/zone/AddZone';
// import AddActivity_Gallery from './pages/activity/AddActivity_Gallery';
// // Manage
// import Manage_Conference from './pages/conference/Manage_Conference';
// import ManageMembers from './pages/member/ManageMembers';
// import ManageZones from './pages/zone/ManageZones';
// import ManageActivities from './pages/activity/ManageActivities';
// import ManageActivities_Gallery from './pages/activity/ManageActivities_Gallery';
// // Profile/Detail
// import DetailConference from './pages/conference/DetailConference';
// import DetailMember from './pages/member/DetailMember';
// import DetailZone from './pages/zone/DetailZone';
// // Edit
// import EditConference from './pages/conference/EditConference';
// import EditMember from './pages/member/EditMember';
// import EditZone from './pages/zone/EditZone';
// // Delete
// import DeleteConference from './pages/conference/DeleteConference';
// import DeleteMember from './pages/member/DeleteMember';
// import DeleteZone from './pages/zone/DeleteZone';

// import Contact from './pages/Contact';
// import About from './pages/About';
// import Zone from './pages/Zone';

  // const ittraining_desc = 'Learn Your Dream IT Courses In Simple, Clear Steps'

      {/* <Route path="/about" element={<About />} />
      <Route path="/zone" element={<Zone />} />
      <Route path="/contact" element={<Contact />} />
      
      <Route path="/conference-registration" element={<Conference_Registration />} />
      <Route path="/registration" element={<Registration project={project} />} />
      <Route path="/login" element={<Login project={project} />} />
      

      <Route path="/dashboard" element={<Dashboard project={project} />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/manage-conference" element={<Manage_Conference />} />
      <Route path="/manage-members" element={<ManageMembers />} />
      <Route path="/manage-zones" element={<ManageZones />} />
      <Route path="/manage-activities" element={<ManageActivities />} />
      <Route path="/add-activity-gallery" element={<AddActivity_Gallery />} />        
      <Route path="/manage-activities-gallery" element={<ManageActivities_Gallery />} />
      <Route path="/add-activity" element={<AddActivity />} />
      <Route path="/content-management" element={<ContentManagement />} />
      <Route path="/add-zone" element={<AddZone />} />

      <Route path="/conference-registration/:slug" element={<DetailConference />} />
      <Route path="/member/:slug" element={<DetailMember />} />
      <Route path="/zone/:slug" element={<DetailZone />} />

      <Route 
        path="/edit-conference-registration/:slug" 
        element={<ProtectedRoute element={EditConference} />}
      />
      <Route path="/edit-member/:slug" 
        element={<EditMember />} 
      />
      <Route 
        path="/edit-zone/:slug" 
        element={<ProtectedRoute element={EditZone} />}
      />

      <Route 
        path="/delete-conference-registration/:slug" 
        element={<ProtectedRoute element={DeleteConference} />}
      />
      <Route 
        path="/delete-member/:slug" 
        element={<ProtectedRoute element={DeleteMember} />}
      />
      <Route 
        path="/delete-zone/:slug" 
        element={<ProtectedRoute element={DeleteZone} />}
      /> */}

      {/* DETAIL PAGES */}
      {/* <Route path="/course-category/:category_id" element={<CategoryDetail project={project} />} />
      <Route path="/course/:course_id" element={<CourseDetail project={project} />} />
      <Route path="/instructor/:instructor_id" element={<InstructorDetail project={project} />} />

      <Route path="/manage-courses" element={<ManageCourses />} />
      <Route path="/course-sample" element={<CourseSample project={project} />} /> */}