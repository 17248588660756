import image from '../../assets/img/it-training-image.png';
import { UilArrowRight } from '@iconscout/react-unicons';

import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';
import missionvision from '../../assets/img/it-training-image.png';

const Banner = () => {
  return (
    <>
    <div id="banner">
      <Swiper 
        spaceBetween={0} 
        breakpoints={{
          1206: {
              slidesPerView: 1, /* 5. NORMAL LAPTOPS (E.G MY OWN) TO LARGE @media screen and (min-width: 1207px)*/
          },
          1024: {
            slidesPerView: 1, /* 4. SMALL LAPTOP SCREENS @media screen and (min-width: 1025px) and (max-width: 1206px) */
          },
          767: {
              slidesPerView: 1, /* 3. MEDIUM SCREENS @media screen and (min-width: 768px) and (max-width: 1024px) */
          },
          576: {
          slidesPerView: 1, /* 2. TABLETS @media screen and (min-width: 577px) and (max-width: 767px) */
          },
          350: {
              slidesPerView: 1, /* 1. SMALL/MOBILE @media screen and (min-width: 351px) and (max-width: 576px) */
          },
          // when window width is >= 0px (default for all sizes)
          0: {
            slidesPerView: 1,
          },
        }}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
          speed={300} 
          loop={true} 
          modules={[Autoplay]}
        >
          <SwiperSlide>          
            <div className="zyswiper">
              <h1>Simplify complex work processes</h1>
              <h2>with robust software and IT services to improve business and income.</h2>
              <button>Get services</button>
            </div>
          </SwiperSlide>

          <SwiperSlide>          
            <div className="zyswiper">
              <h1>Learn your dream IT courses</h1>
              <h2>in simple and clear steps. We break down complex topics into easy guides.</h2>
              <button>Learn courses</button>
            </div>
          </SwiperSlide>
        </Swiper>
        
        <img src={image} alt="image" />
    </div>
    </>
  )
}

export default Banner
