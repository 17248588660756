import Main from './components/Main';
import './styles/App.css';
// import './styles/Responsive.css';
// import './styles/Navbar.css';

function App() {
  return (
    <Main />
  );
}

export default App;
