import React, { useState } from "react";
import { UilHome, UilEnvelope, UilPhone, UilWhatsapp, UilYoutube, UilSearch } from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';

const Header = (props) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div id="header">
      <div id="header-left">
        <h2>{props.company}</h2>
      </div>

      <div id="header-right">
        <ul>
          <li><Link to="/study">IT Training</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/products">Projects</Link></li>
          <li><Link to="/blog">Resources</Link></li>
          <li><Link to="/blog">Apps</Link></li>
          <a href="https://wa.me/message/2QC2GFREBENHD1" title="Chat on WhatsApp"><UilWhatsapp /></a>
          <li><UilSearch /></li>
          {/* <li><a href="tel:+2348021075169" title="Call us"><UilPhone /></a></li>
          <li><a href="mailto:contact@zyzics.com" title="Send us an email"><UilEnvelope /></a></li> */}
        </ul>
        
        <button onClick={() => setShowPopup(true)}>Contact us</button>

        {showPopup && (
          <div className="popup-overlay">
            <div className="popup">
              <h2 className="popup-title">Contact Us</h2>
              <h3><a href="tel:+2348021075169" title="Call us"><UilPhone /> Phone: (+234) 802-107-5169</a></h3>
              
              <h3><a href="mailto:contact@zyzics.com" title="Call us"><UilEnvelope /> Email: contact@zyzics.com</a></h3>
              
              <button className="close-btn" onClick={() => setShowPopup(false)}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
    // <Navbar company={props.company} />
  );
};

export default Header;