import { useEffect } from 'react';
import Header from '../layouts/Header';
import Headline from '../sections/Headline';
import CoreServices from '../sections/CoreServices';
import Section2 from '../sections/Section2';
import Section3 from '../sections/Section3';
import Banner from '../layouts/Banner';

const Home = (props) => {
  useEffect(() => { document.title = `${props.company}`; }, []);

  return (
    <>
    <div id="screen">
      <Header company={props.company} />
      {/* <button>Contact</button> */}
      <Banner />
    </div>
    {/* <Header company={props.company} /> */}

    {/* <Headline />
    <Section2 />
    <Section3 /> */}
    
    {/* <a href="https://wa.me/message/2QC2GFREBENHD1" title="Chat on WhatsApp">WhatsApp</a> */}
  </>
  );
}
export default Home;